import direct_axios from 'axios';
import axios from "./axios";

const token = localStorage.getItem('accessToken');
const config = {headers: {Authorization: `Bearer ${token}`}};

export async function getAffiliateCodesByStoreUuid(uuid) {
    try {
        const result = await axios.get('/api/affiliates/listbystore/' + uuid, config);
        return result.data;
    } catch (error) {
        console.error(error);
        return {data: []};
    }
}
