import React, { useState } from 'react'
import axios from '../api/axios';

const Security = () => {
  const email = localStorage.getItem('profileEmail');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  /* token and headers*/
  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }

  const user_id = localStorage.getItem('userId');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };

  const handleSaveChanges = async (event) => {
    event.preventDefault();
    const id = localStorage.getItem('id')!==null?localStorage.getItem('id'):0;
    const data = {
      'id' : id,
      'email': email,
      'old_password': oldPassword,
      'new_password': newPassword,
      'confirm_password': confirmPassword
    };

    try{
      await axios.post('/api/users/update-password', data, config).then(result=>{
        // alert(result.data.message);
        window.toastr.success(result.data.message, 'Good job!');
      }, (err)=>{
        console.log(err.response.data.message);
        if(err.response.data.message==='Unauthenticated.'){
            window.location.href = '/login';
        } else {
          // alert(err.response.data.message);
          window.toastr.error(err.response.data.message, 'Oops!');
        }
      });
    } catch(e){
      console.log(e);
    }

  }
  return (
    <div className="content-wrapper">

        <div className="container-xxl flex-grow-1 container-p-y">
              <div className="row">
                <div className="col-md-12">
                  <ul className="nav nav-pills flex-column flex-md-row mb-3">
                    <li className="nav-item">
                      <a className="nav-link" href="/profile-settings"><i className="bx bx-user me-1 gh-h-px-20"></i> Account</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link active" href="#"
                        ><i className="bx bx-lock-alt me-1 gh-h-px-20"></i> Security</a
                      >
                    </li>
                    {localStorage.getItem('profileRole').toLowerCase() == 'merchant' &&
                        <li className="nav-item d-none">
                        <a className="nav-link" href="/billing-and-plans"
                            ><i className="bx bx-detail me-1"></i> Billing & Plans </a>
                        </li>
                    }
                  </ul>
                  <div className="card">
                    <h5 className="card-header">Update Password</h5>
                    <div className="card-body">
                      <form id="formUpdatePassword" onSubmit={handleSaveChanges}>
                        <div className="mb-3 col-md-6">
                            <label for="email" className="form-label">Old Password</label>
                            <input
                              className="form-control"
                              type="password"
                              id="old_password"
                              name="old_password"
                              onChange={(e)=>setOldPassword(e.target.value)}
                              placeholder="" />
                        </div>
                        <div className="mb-3 col-md-6">
                            <label for="new password" className="form-label">New Password</label>
                            <input
                              className="form-control"
                              type="password"
                              id="new_password"
                              name="new_password"
                              onChange={(e)=>setNewPassword(e.target.value)}
                              placeholder="" />
                        </div>
                        <div className="mb-3 col-md-6">
                            <label for="confirm password" className="form-label">Confirm Password</label>
                            <input
                              className="form-control"
                              type="password"
                              id="confirm_password"
                              name="confirm_password"
                              onChange={(e)=>setConfirmPassword(e.target.value)}
                              placeholder="" />
                        </div>
                        <button type="submit" className="btn btn-primary deactivate-account" onClick={handleSaveChanges}>Save Changes</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </div>
  )
}

export default Security
