import React, {useEffect, useRef} from "react";

const FilterDatatable = ({ filterText, onFilter, onClear, placeholder}) => (
    <>
        <input
            id="search"
            type="text"
            className='gh-datatable-search'
            placeholder={placeholder}
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        {/*<ClearButton type="button" onClick={onClear}>*/}
        {/*    X*/}
        {/*</ClearButton>*/}
    </>
);

export default FilterDatatable
