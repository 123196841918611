import axios from "axios";

const env = process.env.REACT_APP_APP_ENV;

console.log(env);

let baseURL = '';

if (env === 'development') {
    baseURL = 'https://gripapi.dev.gyfthint.com'; 
  } else if (env === 'staging') {
    baseURL = 'https://api-gyfthint-grip.ebizonstaging.com'; 
  } else if (env === 'production') {
    baseURL = 'https://gripapi.prod.gyfthint.com'; 
  } else {
    baseURL = 'http://localhost:8000'; 
  }

export default axios.create({
    baseURL,
    withXSRFToken :true
})
