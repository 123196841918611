import React, { useState } from 'react'
import axios from '../api/axios';

const ProfileSettings = () => {
  const [profileName, setProfileName] = useState(localStorage.getItem('profileName'));
  const [email, setEmail] = useState(localStorage.getItem('profileEmail'));

  /* token and headers*/
  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }

  const user_id = localStorage.getItem('userId');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };

  const handleSaveChanges = async (event) => {
    event.preventDefault();
    const id = localStorage.getItem('id')!==null?localStorage.getItem('id'):0;
    const data = {
      'name':profileName,
      'email':email
    };

    try{
      await axios.put('/api/users/'+id, data, config).then(result=>{
        alert(result.data.message);
      }, (err)=>{
        console.log(err);
      });
    } catch(e){
      console.log(e);
    }

  }

  return (
    <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
              <div className="row">
                <div className="col-md-12">
                  <ul className="nav nav-pills flex-column flex-md-row mb-3">
                    <li className="nav-item">
                      <a className="nav-link active" href="#"><i className="bx bx-user me-1 gh-h-px-20"></i> Account</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/profile-security"
                        ><i className="bx bx-lock-alt me-1 gh-h-px-20"></i> Security</a
                      >
                    </li>

                    {localStorage.getItem('profileRole').toLowerCase() == 'merchant' &&
                        <li className="nav-item d-none">
                        <a className="nav-link" href="/billing-and-plans"
                            ><i className="bx bx-detail me-1"></i> Billing & Plans </a>
                        </li>
                    }
                  </ul>
                  <div className="card mb-4">
                    <h5 className="card-header">{localStorage.getItem('profileRole').toUpperCase()=='MERCHANT'?'Merchant':'Profile'} Details</h5>
                    <div className="card-body">
                      <div className="d-flex align-items-start align-items-sm-center gap-4 d-none">
                        <img
                          src="../assets/img/avatars/1.png"
                          alt="user-avatar"
                          className="d-block rounded"
                          height="100"
                          width="100"
                          id="uploadedAvatar" />
                        <div className="button-wrapper">
                          <label className="btn btn-primary me-2 mb-4" tabIndex="0">
                            <span className="d-none d-sm-block">Upload new photo</span>
                            <i className="bx bx-upload d-block d-sm-none"></i>
                            <input
                              type="file"
                              id="upload"
                              className="account-file-input"
                              hidden
                              accept="image/png, image/jpeg" />
                          </label>
                          <button type="button" className="btn btn-outline-secondary account-image-reset mb-4">
                            <i className="bx bx-reset d-block d-sm-none"></i>
                            <span className="d-none d-sm-block">Reset</span>
                          </button>

                          <p className="text-muted mb-0">Allowed JPG, GIF or PNG. Max size of 800K</p>
                        </div>
                      </div>
                    </div>
                    <hr className="my-0 " />
                    <div className="card-body">
                      <form id="formAccountSettings" onSubmit={handleSaveChanges}>
                        <div className="row">
                          <div className="mb-3 col-md-6">
                            <label className="form-label">{localStorage.getItem('profileRole').toUpperCase()} Name</label>
                            <input
                              className="form-control"
                              type="text"
                              id="name"
                              name="name"
                              autoFocus
                              value={profileName}
                              onChange={(e)=>setProfileName(e.target.value)}/>
                          </div>
                          <div className="mb-3 col-md-6">
                            <label className="form-label">E-mail</label>
                            <input
                              className="form-control"
                              type="text"
                              id="email"
                              name="email"
                              value={email}
                              onChange={(e)=>setEmail(e.target.value)}
                              placeholder="john.doe@example.com" />
                          </div>
                        </div>
                        <div className="mt-2">
                          <button type="submit" className="btn btn-primary me-2" onClick={handleSaveChanges}>Save changes</button>
                          <button type="reset" className="btn btn-outline-secondary">Cancel</button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="card d-none">
                    <h5 className="card-header">Delete Account</h5>
                    <div className="card-body">
                      <div className="mb-3 col-12 mb-0">
                        <div className="alert alert-warning">
                          <h6 className="alert-heading mb-1">Are you sure you want to delete your account?</h6>
                          <p className="mb-0">Once you delete your account, there is no going back. Please be certain.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </div>
  )
}

export default ProfileSettings
