import React, { useState } from 'react';
import direct_axios from 'axios';

const Feedback = () => {
  const [subject, setSubject] = useState('');
  const store = localStorage.getItem('currentActiveStore');
  const [username] = useState(localStorage.getItem('profileEmail'));
  const [message, setMessage] = useState('');
  const [isOnProcess, setIsOnProcess] = useState(false);
  const external_api = process.env.REACT_APP_EXTERNAL_API_ENDPOINT;

  const [selectedSubject, setSelectedSubject] = useState('');  // State to track selected subject

  /* token and headers */
  const token = localStorage.getItem('accessToken');
  if (token === '' || token === undefined || token === null) {
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value); // Update the selected subject state

    if (e.target.value !== 'Others') {
      setSubject(e.target.value);  // Set subject to the selected value, if not 'Others'
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsOnProcess(true);  // Disable the submit button to avoid multiple submissions

    // Validate subject and message fields
    if (!selectedSubject || !message.trim()) {
      window.toastr.error('Subject and message are required.', 'Error!');
      setIsOnProcess(false);  // Re-enable the submit button
      return;
    }

    // Prepare the feedback data
    const feedbackData = {
      subject: selectedSubject === 'Others' ? subject : selectedSubject,  // If 'Others' is selected, use the custom subject
      message: message,
      store: store,
      username: username
    };

    try {
      // Sending feedback data to the server using axios
      const response = await direct_axios.post(external_api + '/merchant-feedback', feedbackData, config);
      if (response.status === 200) {
        window.toastr.success('Feedback submitted successfully!', 'Success!');
        // Reset form fields after successful submission
        setSelectedSubject('');
        setSubject('');
        setMessage('');
      } else {
        window.toastr.error('Something went wrong, please try again!', 'Error!');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      window.toastr.error('Error submitting feedback, please try again later.', 'Error!');
    } finally {
      setIsOnProcess(false);  // Re-enable the submit button
    }
  };

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y d-flex justify-content-center" style={{ paddingTop: '50px' }}>
        <div className="row align-items-start justify-content-center w-100">
          <div className="col-md-8">
            <div className="card mb-4">
              <h5 className="card-header">{localStorage.getItem('profileRole').toUpperCase() === 'MERCHANT' ? 'Store' : 'Store'}: {localStorage.getItem('currentActiveStore')}</h5>
              <hr className="my-0 " />
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="align-items-start gap-4">
                    <p className="grip-form-detail-label">
                      Thank you for your feedback! Please let us know how we can improve your experience. Your input is valuable to us!
                    </p>

                    <div className="row mt-2">
                      <div className="col-md-6">
                        <div className="mb-2">
                          <label className="mb-0 grip-form-label" htmlFor="modalEditUserFirstName">Select Subject:</label>
                          <select
                            type="text"
                            id="label"
                            name="label"
                            className="form-control"
                            value={selectedSubject} // Bind the selectedSubject state to the dropdown
                            onChange={handleSubjectChange} // Update the state on selection change
                          >
                            <option value=''>Choose Options</option>
                            <option value='Onboarding'>Onboarding</option>
                            <option value='Customize Wishlist Button'>Customize Wishlist Button</option>
                            <option value='License'>License</option>
                            <option value='Promotions'>Promotions</option>
                            <option value='Orders'>Orders</option>
                            <option value='Others'>Others</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    {/* Show the "Others" input field only if the "Others" option is selected */}
                    {selectedSubject === 'Others' && (
                      <div className="row mt-2">
                        <div className="col-md-6">
                          <div className="mb-2">
                            <label className="mb-0 grip-form-label" htmlFor="modalEditUserFirstName">Specify Subject</label>
                            <input
                              type="text"
                              id="others"
                              name="others"
                              className="form-control"
                              maxLength={20}
                              placeholder="Type here..."
                              onChange={(e) => setSubject(e.target.value)} // Update the subject state
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row mt-2">
                      <div className="col-md-12">
                        <div className="mb-2">
                          <label className="mb-0 grip-form-label" htmlFor="modalEditUserFirstName">Message</label>
                          <textarea
                            type="text"
                            id="comments"
                            name="comments"
                            className="form-control"
                            placeholder="Your Comments"
                            rows={4}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)} // Update the message state
                          />
                        </div>
                      </div>
                    </div>

                    <button 
                      type="submit" 
                      className="btn btn-primary me-3 mt-4" 
                      disabled={isOnProcess} // Disable button while submitting
                    >
                      {isOnProcess ? 'Submitting...' : 'Submit'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feedback;