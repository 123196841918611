import React, {useState, useEffect, useMemo, useRef} from "react";
import axios from "../api/axios";
import direct_axios from 'axios';
import ValueBox from "../components/ValueBox";
import DateRange from "../components/DateRange";
import DataTable from "react-data-table-component";
import FilterDatatable from "../components/FilterDatatable";
import {getAffiliateCodesByStoreUuid} from "../api/affiliateCodes";
import {getOrdersByStoreUuid} from "../api/orders";
import {formatPrice, formatQty} from "../helpers/number";

const Orders = () => {
    const orderDetailsModalRef = useRef(null);

    const [role, setRole] = useState(localStorage.getItem('profileRole'));
    const [isLoading, setIsLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [len, setLen] = useState(0);
    const [affiliateDetails, setAffiliateDetails] = useState([]);
    const [commisionRate, setCommissionRate] = useState(0);
    const stores = JSON.parse(localStorage.getItem('stores'));
    const [totalGMV, setTotalGMV] = useState('0.00');
    const [totalComission, setTotalComission] = useState('0.00');
    const external_api = process.env.REACT_APP_EXTERNAL_API_ENDPOINT;
    const [selectedDate, setSelectedDate] = useState('');

    // Details Modal
    const [activeOrder, setActiveOrder] = useState('');

    // Filters
    const [currentFilters, setCurrentFilters] = useState({search: ''});
    const [fulfilmentStatusOptions, setFulfilmentStatusOptions] = useState([]);
    const [openDateRangeFilter, setOpenDateRangeFilter] = useState(false);
    const [merchantOptions, setMerchantOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);

    // Search Filter
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    // Order Details Modal
    const [subTotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [shippingAndHandling, setShippingAndHandling] = useState(0);
    const [taxes, setTaxes] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);


    /* setting of forms ends here */

    /* token and headers*/
    const token = localStorage.getItem('accessToken');
    if (token === '' || token === undefined || token === null) {
        localStorage.setItem('accessToken', '');
        localStorage.setItem('menuAccess', []);
        localStorage.setItem('profileRole', '');
        window.location.href = '/login';
    }
    const currentActiveStoreId = localStorage.getItem('currentActiveStoreId');
    // const currentActiveStoreName = localStorage.getItem('currentActiveStore');
    // let store_id = currentActiveStoreId === '' || currentActiveStoreId === undefined ? 1 : currentActiveStoreId;
    // let store_name = currentActiveStoreName === '' || currentActiveStoreName === undefined ? '' : currentActiveStoreName;
    const [selectedStore, setSelectedStore] = useState(currentActiveStoreId);
    const [storeId, setStoreId] = useState(currentActiveStoreId);
    // const [storeName, setStoreName] = useState(currentActiveStoreName);

    const userId = localStorage.getItem('userId');
    const config = {
        headers: {Authorization: `Bearer ${token}`}
    };

    useEffect(() => {
        if (activeOrder) {
            let orderSubTotal = 0;
            orderSubTotal = orderSubTotal + activeOrder.hint.price;
            setSubTotal(orderSubTotal);

            let orderDiscount = 0;
            orderDiscount = activeOrder?.discount || 0;
            setDiscount(orderDiscount);

            let orderShippingAndHandling = 0;
            orderShippingAndHandling = activeOrder?.shipping_and_handling_fee || 0;
            setShippingAndHandling(orderShippingAndHandling);

            const orderTaxes = 0;
            // orderShippingAndHandling = activeOrder?.shipping_and_handling_fee || 0;
            setTaxes(orderTaxes);

            let orderGrandTotal = 0;
            orderGrandTotal = (orderSubTotal - orderDiscount) + orderShippingAndHandling + orderTaxes;
            setGrandTotal(orderGrandTotal);
        }
    }, [activeOrder]);


    useEffect(() => {
        const currentDate = new Date().toISOString().split('T')[0];  // Get current date in 'YYYY-MM-DD' format
        setSelectedDate(currentDate); // Set it as the default value
    }, []);

    useEffect(() => {
        // Trigger fetchTable when storeId changes
        fetchTable();
        setIsLoading(true);
    }, [storeId]);

    useEffect(() => {
        const total = filteredOrders.reduce((sum, product) => {
            // Check if the product's hint is not 'CANCEL' before adding its price
            if (product.status !== 'CANCEL') {
                return sum + product.hint.price;
            }
            return sum;
        }, 0);
        setTotalGMV(total);

        const totalCom = (commisionRate / 100) * total;
        setTotalComission(totalCom);

    }, [filteredOrders]);

    const fetchTable = async () => {
        // Set loading state and clear previous orders
        setIsLoading(true);
        setOrders([]);
        setFilteredOrders([]);
        setAffiliateDetails(null);
        setCommissionRate(0);

        try {
            console.log('storeId', storeId)

            const [affiliateData, orders] = await Promise.all([
                getAffiliateCodesByStoreUuid(storeId),
                getOrdersByStoreUuid(storeId, role === 'merchant' ? {status: 'PURCHASED'} : {}),
            ])

            if (affiliateData.data && affiliateData.data.length) {
                setAffiliateDetails(affiliateData.data[0]);
                setCommissionRate(affiliateData.data[0].affiliate_commission_percentage);
            }

            setOrders(orders.data);
            setFilteredOrders(orders.data);

            const uniqueStores = [...new Set(orders.data.map(item => item.hint.store))].filter(i => i).sort((a, b) => a.localeCompare(b));
            setMerchantOptions(uniqueStores);

            const uniqueStatuses = [...new Set(orders.data.map(item => item.status))].filter(i => i).sort((a, b) => a.localeCompare(b));
            setStatusOptions(uniqueStatuses);

            setIsLoading(false);
        } catch (e) {
            // Handle error by logging it and resetting loading state
            console.log(e);
            setIsLoading(false);
        }
    };

    const handleChange = async (event) => {
        const newStoreId = event.target.value;
        setStoreId(newStoreId); // This will trigger useEffect that calls fetchTable
        setSelectedStore(newStoreId);
        if (newStoreId) localStorage.setItem('currentActiveStoreId', newStoreId);
        else localStorage.removeItem('currentActiveStoreId');
    };

    const columns = [
        ...(role.toLowerCase() === 'admin' ? [{
            name: "STORE",
            cell: (row) => <span className='text-wrap'>{row.hint.store}</span>,
            width: '12%',
        }] : []),
        {
            name: "ORDER #",
            cell: (row) => <span>{row.shopify_order_no || ''}</span>,
            width: '8%',
        },
        {
            name: "PRODUCT NAME",
            cell: (row) => (
                <span className='text-wrap' onClick={() => {
                    setActiveOrder(row)
                }}>{row.hint.name}
                    <svg data-bs-toggle="modal"
                         data-bs-target="#orderDetailsModal" className='ms-2 cursor-pointer' width="16" height="12"
                         viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8Z"
                            fill="#121872"/>
                        <path
                            d="M15.4698 5.83C14.8817 4.30882 13.8608 2.99331 12.5332 2.04604C11.2056 1.09878 9.62953 0.561286 7.99979 0.5C6.37005 0.561286 4.79398 1.09878 3.46639 2.04604C2.1388 2.99331 1.11787 4.30882 0.529787 5.83C0.490071 5.93985 0.490071 6.06015 0.529787 6.17C1.11787 7.69118 2.1388 9.00669 3.46639 9.95396C4.79398 10.9012 6.37005 11.4387 7.99979 11.5C9.62953 11.4387 11.2056 10.9012 12.5332 9.95396C13.8608 9.00669 14.8817 7.69118 15.4698 6.17C15.5095 6.06015 15.5095 5.93985 15.4698 5.83ZM7.99979 9.25C7.357 9.25 6.72864 9.05939 6.19418 8.70228C5.65972 8.34516 5.24316 7.83758 4.99718 7.24372C4.75119 6.64986 4.68683 5.99639 4.81224 5.36596C4.93764 4.73552 5.24717 4.15642 5.70169 3.7019C6.15621 3.24738 6.73531 2.93785 7.36574 2.81245C7.99618 2.68705 8.64965 2.75141 9.24351 2.99739C9.83737 3.24338 10.3449 3.65994 10.7021 4.1944C11.0592 4.72886 11.2498 5.35721 11.2498 6C11.2485 6.86155 10.9056 7.68743 10.2964 8.29664C9.68722 8.90584 8.86133 9.24868 7.99979 9.25Z"
                            fill="#121872"/>
                    </svg>
                </span>
            ),
            minWidth: '28%', // Ensuring the PRODUCT NAME column spans enough width
        },
        {
            name: "DATE",
            selector: (row) => row.created_at,
            sortable: true,
            cell: (row) => <span>{new Date(row.created_at).toLocaleDateString('en-us')}</span>,
            width: '8%',
            sortFunction: (a, b) => (new Date(a.created_at) - new Date(b.created_at)),
        },
        {
            name: "RECIPIENT",
            selector: (row) => row.purchased_for_userId,
            cell: (row) => <span
                className='text-wrap'>{`${row.purchased_for_user.first_name} ${row.purchased_for_user.last_name}`}</span>,
            minWidth: '8%',
            sortable: true,
            sortFunction: (a, b) => {
                const aName = `${a.purchased_for_user.first_name} ${a.purchased_for_user.last_name}`;
                const bName = `${b.purchased_for_user.first_name} ${b.purchased_for_user.last_name}`;
                return aName.localeCompare(bName);
            },
        },
        {
            name: "BUYER",
            selector: (row) => row.purchased_by_userId,
            cell: (row) => <span
                className='text-wrap'>{`${row.purchased_by_user.first_name} ${row.purchased_by_user.last_name}`}</span>,
            minWidth: '8%',
            sortable: true,
            sortFunction: (a, b) => {
                const aName = `${a.purchased_by_user.first_name} ${a.purchased_by_user.last_name}`;
                const bName = `${b.purchased_by_user.first_name} ${b.purchased_by_user.last_name}`;
                return aName.localeCompare(bName);
            },
        },
        {
            name: "HALO ORDER",
            cell: (row) => <span>{row.is_halo_order ? 'Yes' : 'No'}</span>,
            sortable: true,
            sortFunction: (a, b) => (a.is_halo_order - b.is_halo_order),
            minWidth: '8%',
        },
        {
            name: "GMV",
            cell: (row) => <span>${row.hint.price > 0 ? row.hint.price.toFixed(2) : '0.00'}</span>,
            sortable: true,
            sortFunction: (a, b) => (a.hint.price - b.hint.price),
        },
        {
            name: "COMM.",
            cell: (row) => `$${row.hint.price > 0 ? (row.hint.price * (commisionRate / 100)).toFixed(2) : '0.00'}`,
            sortable: true,
            sortFunction: (a, b) => ((a.hint.price * (commisionRate / 100)) - (b.hint.price * (commisionRate / 100))),
        },
        {
            name: "STATUS",
            cell: (row) => <span
                className='text-capitalize'>{row.status ? row.status.toLowerCase().replace('_', ' ') : ''}</span>,
            sortable: true,
            sortFunction: (a, b) => (a.status.localeCompare(b.status)),
        },
        {
            name: "PAYMENT RECEIVED",
            cell: (row) => <span>${row.received_amount > 0 ? row.received_amount.toFixed(2) : '0.00'}</span>,
            sortable: true,
            sortFunction: (a, b) => (a.received_amount - b.received_amount),
        },
        {
            name: "FULFILMENT STATUS",
            cell: (row) => <span className='text-wrap text-capitalize'>{row.fulfilment_status ? row.fulfilment_status.toLowerCase().replace('_', ' ') : ''}</span>,
            // sortable: true,
            // sortFunction: (a, b) => (a.fulfilment_status.localeCompare(b.fulfilment_status)),
        },
        // ...(role.toLowerCase() === 'admin' ? [{
        //     name: "STORE",
        //     cell: (row) => {
        //         row.payment_status = 'succeeded' ? <span className="badge bg-label-primary me-1">yes</span> :
        //             <span className="badge bg-label-danger me-1">no</span>
        //     }
        // }] : []),
    ];

    const handleFilterByDateFrom = (filterDateFrom) => {
        setCurrentFilters({
            ...currentFilters,
            filterDateFrom,
        })
    }

    const handleFilterByDateTo = (filterDateTo) => {
        setCurrentFilters({
            ...currentFilters,
            filterDateTo,
        })
    }

    const handleFilterMerchant = (merchant) => {
        setCurrentFilters({
            ...currentFilters,
            merchant,
        })
    }

    const handleFilterStatus = (status) => {
        setCurrentFilters({
            ...currentFilters,
            status,
        })
    }

    useEffect(() => {
        let tempOrders = [...orders];
        const filters = Object.keys(currentFilters);
        for (let i = 0; i < filters.length; i++) {
            const filterType = filters[i];
            const filterValue = currentFilters[filterType];

            if (filterType === 'filterDateFrom') {
                if (filterValue) {
                    tempOrders = tempOrders.filter(h => new Date(h.created_at) > new Date(`${filterValue}T00:00:00`));
                }
            } else if (filterType === 'filterDateTo') {
                if (filterValue) {
                    tempOrders = tempOrders.filter(h => new Date(h.created_at) < new Date(`${filterValue}T23:59:59`));
                }
            }

            if (filterType === 'status') {
                if (filterValue && (filterValue !== 'all status')) {
                    tempOrders = tempOrders.filter(h => h.status && (h.status.toLowerCase() === filterValue.toLowerCase()));
                }
            }

            if (filterType === 'merchant') {
                if (filterValue && (filterValue !== 'all merchant')) {
                    tempOrders = tempOrders.filter(h => h.hint && h.hint.store && (h.hint.store.toLowerCase() === filterValue.toLowerCase()));
                }
            }

            if (filterType === 'search') {
                if (filterValue) {
                    const normalizedFilter = filterValue.toLowerCase();
                    tempOrders = tempOrders.filter(order => {
                        const name = order?.hint?.name;
                        if (!name) return false;
                        return name.toLowerCase().includes(normalizedFilter);
                    });
                }
            }
        }
        setFilteredOrders(tempOrders);
        setLen(tempOrders.length);
    }, [currentFilters]);


    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterDatatable
                onFilter={e => {
                    setCurrentFilters({
                        ...currentFilters,
                        search: e.target.value,
                    });
                    setFilterText(e.target.value);
                }}
                onClear={handleClear}
                filterText={filterText}
                placeholder={'Search product name'}
            />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="main-kpis">
                <div className="row w-100 m-0 justify-content-between">
                    <ValueBox
                        label={'Total Orders'}
                        value={formatQty(filteredOrders.length)}
                        loading={isLoading}
                        icon={'tf-icons bx bx-package'}
                        iconColor={'#93C05C'}
                    />
                    <ValueBox
                        label={'Total GMV'}
                        // value={totalGMV > 0 ? `$${Number(totalGMV.toFixed(2)).toLocaleString('en-US')}` : '$0.00'}
                        value={totalGMV > 0 ? `$${formatPrice(totalGMV)}` : '$0.00'}
                        loading={isLoading}
                        icon={'bx bx-line-chart'}
                        iconColor={'#FE91DA'}
                    />
                    <ValueBox
                        label={'Conversion Rate'}
                        value={'0%'}
                        loading={isLoading}
                        icon={'tf-icons bx bx-chart'}
                        iconColor={'#CE73E0'}
                        comingSoon={true}
                    />
                    <ValueBox
                        label={'Total Commisions'}
                        // value={totalComission > 0 ? `$${Number(totalComission.toFixed(2)).toLocaleString('en-US')}` : '$0.00'}
                        value={totalComission > 0 ? `$${formatPrice(totalComission)}` : '$0.00'}
                        loading={isLoading}
                        icon={'bx bx-money'}
                        iconColor={'#79CC13'}
                    />
                    <ValueBox
                        label={'Commission Rate'}
                        value={commisionRate + '%'}
                        loading={isLoading}
                        icon={'tf-icons fas fa-percent'}
                        iconColor={'#FF7D80'}
                    />
                </div>
            </div>
            {role.toLowerCase() === 'admin' &&
                <div className="main-kpis">
                    <select className="form-select border border-default rounded w-20 ms-1 gh-appearance-none"
                            onChange={handleChange}
                            value={selectedStore}>
                        <option value="">All Stores</option>
                        {stores.map((store, index) => (
                            <option key={index} value={store.uuid}>
                                {store.store_name}
                            </option>
                        ))}
                    </select>
                </div>
            }
            <div className="row mb-12">
                <div id="table-list-container" className="col-md-12">
                    <div className="card">
                        <h5 className="card-header">
                            <div className="kpi-card-info">
                                <span>Gyfthint</span>
                                <p>Orders</p>
                            </div>
                        </h5>
                        <div className="table-responsive text-nowrap">
                            <div className='d-flex'>
                                <div className="dropdown me-1">
                                    <button className="btn dropdown-toggle gh-select-store" type="button"
                                            onClick={() => setOpenDateRangeFilter(!openDateRangeFilter)}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M6.99967 13.6673C3.31777 13.6673 0.333008 10.6825 0.333008 7.00065C0.333008 3.31875 3.31777 0.333984 6.99967 0.333984C10.6815 0.333984 13.6663 3.31875 13.6663 7.00065C13.6663 10.6825 10.6815 13.6673 6.99967 13.6673ZM6.99967 12.334C9.94521 12.334 12.333 9.94618 12.333 7.00065C12.333 4.05513 9.94521 1.66732 6.99967 1.66732C4.05415 1.66732 1.66634 4.05513 1.66634 7.00065C1.66634 9.94618 4.05415 12.334 6.99967 12.334ZM7.66634 7.00065H10.333V8.33398H6.33301V3.66732H7.66634V7.00065Z"
                                                    fill="#4B5563"/>
                                            </svg>
                                            <span className='ms-2'>Date Range</span>
                                        </div>
                                    </button>
                                    <DateRange
                                        openDateRangeFilter={openDateRangeFilter}
                                        setOpenDateRangeFilter={setOpenDateRangeFilter}
                                        currentFilters={currentFilters}
                                        handleFilterByDateFrom={handleFilterByDateFrom}
                                        handleFilterByDateTo={handleFilterByDateTo}/>
                                </div>
                                <div className="dropdown mx-1">
                                    <button className="btn dropdown-toggle gh-select-store" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M6.25467 0C6.50713 0 6.738 0.142667 6.85093 0.368527L7.33333 1.33333H11.3333C11.7015 1.33333 12 1.63181 12 2V9.33333C12 9.70153 11.7015 10 11.3333 10H7.07867C6.8262 10 6.59533 9.85733 6.4824 9.63147L6 8.66667H1.33333V12.6667H0V0H6.25467ZM5.8426 1.33333H1.33333V7.33333H6.82407L7.49073 8.66667H10.6667V2.66667H6.50927L5.8426 1.33333Z"
                                                    fill="#4B5563"/>
                                            </svg>
                                            <span className='ms-2'>Fulfillment Status</span>
                                        </div>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li className="dropdown-item">All Fulfilment Status</li>
                                    </ul>
                                </div>
                                {role.toLowerCase() === 'admin' &&
                                    <div className="dropdown mx-1">
                                        <button className="btn dropdown-toggle gh-select-store" type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                            <div className="d-flex justify-content-center align-items-center">
                                            <span className="bx bxs-store-alt gh-h-px-20 me-2"
                                                  style={{marginTop: '2px'}}></span>
                                                <span className='ms-2'>Merchants</span>
                                            </div>
                                        </button>
                                        <ul className="dropdown-menu"
                                            style={{maxHeight: '300px', overflowY: 'scroll', overflowX: 'hidden'}}>
                                            <li className="dropdown-item" onClick={() => {
                                                handleFilterMerchant('all merchants')
                                            }}>All Merchants
                                            </li>
                                            {merchantOptions.map(merchant => (
                                                <li className="dropdown-item text-capitalize" key={merchant}
                                                    onClick={() => {
                                                        handleFilterMerchant(merchant)
                                                    }}>
                                                    {merchant.toLowerCase().replace('_', ' ')}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                }
                                <div className="dropdown mx-1">
                                    <button className="btn dropdown-toggle gh-select-store" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M6.25467 0C6.50713 0 6.738 0.142667 6.85093 0.368527L7.33333 1.33333H11.3333C11.7015 1.33333 12 1.63181 12 2V9.33333C12 9.70153 11.7015 10 11.3333 10H7.07867C6.8262 10 6.59533 9.85733 6.4824 9.63147L6 8.66667H1.33333V12.6667H0V0H6.25467ZM5.8426 1.33333H1.33333V7.33333H6.82407L7.49073 8.66667H10.6667V2.66667H6.50927L5.8426 1.33333Z"
                                                    fill="#4B5563"/>
                                            </svg>
                                            <span className='ms-2'>Status</span>
                                        </div>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li className="dropdown-item" onClick={() => {
                                            handleFilterMerchant('all status')
                                        }}>All Status
                                        </li>
                                        {statusOptions.map(status => (
                                            <li className="dropdown-item text-capitalize" key={status} onClick={() => {
                                                handleFilterStatus(status)
                                            }}>
                                                {status.toLowerCase().replace('_', ' ')}
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* Show applied filters */}
                                {
                                    Object.keys(currentFilters).map(filter => {
                                        if (filter === 'tab' || filter === 'search') return (<></>);
                                        let dateFormat = '';
                                        if ((filter === 'filterDateFrom' || filter === 'filterDateTo') && currentFilters[filter] !== '') {
                                            const d = currentFilters[filter].split('-'); // YYYY-MM-DD -> MM/DD/YYYY
                                            dateFormat = d[1] + '/' + d[2] + '/' + d[0];
                                        }
                                        return (
                                            <div className='gh-applied-filter-item ps-3 mx-1 text-capitalize'
                                                 key={filter}>
                                                {filter === 'filterDateTo' && 'Up To: '}
                                                {filter === 'filterDateFrom' && 'From: '}
                                                {(filter === 'filterDateFrom' || filter === 'filterDateTo') && dateFormat}
                                                {(filter !== 'filterDateFrom' && filter !== 'filterDateTo') && currentFilters[filter].toLowerCase().replace('_', ' ')}
                                                <span className='ms-2 me-1 cursor-pointer' onClick={() => {
                                                    const temp = {...currentFilters};
                                                    delete temp[filter];
                                                    setCurrentFilters(temp);
                                                }}>
                                                <svg width="9" height="9" viewBox="0 0 9 9" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M1.23341 8.58341L0.416748 7.76675L3.68341 4.50008L0.416748 1.23341L1.23341 0.416748L4.50008 3.68341L7.76675 0.416748L8.58341 1.23341L5.31675 4.50008L8.58341 7.76675L7.76675 8.58341L4.50008 5.31675L1.23341 8.58341Z"
                                                        fill="#4B5563"/>
                                                </svg>
                                            </span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/*{isLoading &&*/}
                            {/*    <div className="table-responsive text-nowrap">*/}
                            {/*        <hr/>*/}
                            {/*        <table className="table">*/}
                            {/*            <thead>*/}
                            {/*            <tr>*/}
                            {/*                {*/}
                            {/*                    columns.filter(c => !c.omit).map((c, i) => (*/}
                            {/*                        <th className={[5, 9, 10].includes(i) ? 'text-wrap' : ''}*/}
                            {/*                            key={c.name}>{c.name}</th>))*/}
                            {/*                }*/}
                            {/*            </tr>*/}
                            {/*            </thead>*/}
                            {/*            <tbody>*/}
                            {/*            <tr>*/}
                            {/*                {*/}
                            {/*                    columns.filter(c => !c.omit).map((_, i) => (*/}
                            {/*                        <td key={i}>*/}
                            {/*                            <div className="loading-content"><h5*/}
                            {/*                                className="loading-long"></h5>*/}
                            {/*                            </div>*/}
                            {/*                        </td>*/}
                            {/*                    ))*/}
                            {/*                }*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                {*/}
                            {/*                    columns.filter(c => !c.omit).map((_, i) => (*/}
                            {/*                        <td key={i}>*/}
                            {/*                            <div className="loading-content"><h5*/}
                            {/*                                className="loading-long"></h5>*/}
                            {/*                            </div>*/}
                            {/*                        </td>*/}
                            {/*                    ))*/}
                            {/*                }*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                {*/}
                            {/*                    columns.filter(c => !c.omit).map((_, i) => (*/}
                            {/*                        <td key={i}>*/}
                            {/*                            <div className="loading-content"><h5*/}
                            {/*                                className="loading-long"></h5>*/}
                            {/*                            </div>*/}
                            {/*                        </td>*/}
                            {/*                    ))*/}
                            {/*                }*/}
                            {/*            </tr>*/}
                            {/*            </tbody>*/}
                            {/*        </table>*/}
                            {/*    </div>}*/}
                            {/*{!isLoading && filteredOrders.length === 0 &&*/}
                            {/*    <div className="table-responsive text-nowrap">*/}
                            {/*        <hr/>*/}
                            {/*        <table className="table">*/}
                            {/*            <thead>*/}
                            {/*            <tr>*/}
                            {/*                {*/}
                            {/*                    columns.filter(c => !c.omit).map((c, i) => (*/}
                            {/*                        <th className={[5, 9, 10].includes(i) ? 'text-wrap' : ''}*/}
                            {/*                            key={i}>{c.name}</th>))*/}
                            {/*                }*/}
                            {/*            </tr>*/}
                            {/*            </thead>*/}
                            {/*            <tbody>*/}
                            {/*            <tr>*/}
                            {/*                <td colSpan={columns.length}>No records found.</td>*/}
                            {/*            </tr>*/}
                            {/*            </tbody>*/}
                            {/*        </table>*/}
                            {/*    </div>}*/}
                            {/*{!isLoading &&*/}
                            <div className="table-responsive text-nowrap">
                                <hr/>
                                <DataTable
                                    columns={columns}
                                    data={filteredOrders}
                                    noDataComponent=""
                                    // noDataComponent={
                                    //     // <td colSpan={columns.length}>No records found.</td>
                                    // }
                                    highlightOnHover
                                    persistTableHead
                                    pagination
                                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                    subHeader
                                    subHeaderComponent={subHeaderComponentMemo}
                                />
                            </div>
                            {/*}*/}
                        </div>
                        <br/>
                    </div>
                </div>
            </div>

            {/* View Order Details Modal*/}
            <div className="modal fade" id="orderDetailsModal" tabIndex="-1" aria-hidden="true"
                 ref={orderDetailsModalRef}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            <div className="mb-6">
                                <h4 className="mb-2 gh-order-summary-title">Order
                                    #: {activeOrder.order_number}</h4>
                            </div>
                            <hr/>
                            <div className="gh-order-details">
                                <div className='gh-order-summary-hint mb-4'>
                                    {
                                        activeOrder.hint && (
                                            <div>HINT - {activeOrder.hint.name}</div>
                                        )
                                    }
                                </div>
                                <table className="table gh-order-summary-table">
                                    <thead>
                                    <tr>
                                        <th>PRODUCT NAME</th>
                                        <th>PRICE</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        activeOrder.hint && (
                                            <tr>
                                                <td>{activeOrder.hint.name}</td>
                                                <td>${activeOrder.hint.price > 0 ? activeOrder.hint.price.toFixed(2) : '0.00'}</td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                                <div className='d-flex justify-content-end mt-3'>
                                    <div className='w-50 px-5'>
                                        <div
                                            className='mb-1 gh-order-summary-item d-flex align-items-center justify-content-between'>
                                            <div className='label'>Subtotal</div>
                                            <div className='value'>${subTotal > 0 ? subTotal.toFixed(2) : '0.00'}</div>
                                        </div>
                                        <div
                                            className='mb-1 gh-order-summary-item d-flex align-items-center justify-content-between'>
                                            <div className='label'>Discounts</div>
                                            <div className='value'>${discount > 0 ? discount.toFixed(2) : '0.00'}</div>
                                        </div>
                                        <div
                                            className='mb-1 gh-order-summary-item d-flex align-items-center justify-content-between'>
                                            <div className='label'>Shipping & Handling</div>
                                            <div
                                                className='value'>${shippingAndHandling > 0 ? shippingAndHandling.toFixed(2) : '0.00'}</div>
                                        </div>
                                        <div
                                            className='mb-1 gh-order-summary-item d-flex align-items-center justify-content-between'>
                                            <div className='label'>Taxes</div>
                                            <div className='value'>${taxes > 0 ? taxes.toFixed(2) : '0.00'}</div>
                                        </div>
                                        <div
                                            className='gh-order-summary-item d-flex align-items-center justify-content-between'>
                                            <div className='label'>Grand Total</div>
                                            <div
                                                className='value grand-total'>${grandTotal > 0 ? grandTotal.toFixed(2) : '0.00'}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Orders
