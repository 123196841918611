import React, { useEffect, useState } from "react";
import axios from "../api/axios";

const Users = () => {
  const [role, setRole] = useState(localStorage.getItem('profileRole'));
  const [modal, setModal] = useState('hide');
  const [modalName, setModalName] = useState('');
  const [canAdd, setCanAdd] = useState(0);
  const [canEdit, setCanEdit] = useState(0);
  const [canDelete, setCanDelete] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isOnProcess, setIsOnProcess]=useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [availableLicenses, setAvailableLicenses] = useState([]);
  const [license, setSelectedLicense] = useState(0);
  const [licensesLen, setLicensesLen] = useState(0);
  const [availableLicense, setAvailableLicense] = useState(0);
  const [usedLicenseLen, setUsedLicenseLen] = useState(0);
  const [currentLicense, setCurrentLicense] = useState(0);

  /* setting of forms */
  const [users, SetUsers] = useState([]);
  const [len, setLen] = useState(0);
  const [moduleName, setModuleName] = useState('');
  const [moduleDescription, setModuleDescription] = useState('');
  const [moduleIcon, setModuleIcon] = useState('');

  const [id, setId] = useState(0);
  const [uuid, setUuid] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [roleid, setRoleId] = useState(4); //customer - default
  const [isEnabled, setIsEnabled] = useState(true);
  const [isEnabledChecked, setIsEnabledChecked] = useState('checked');
  const [licenseLabel, setLicenseLabel] = useState('Available License');
  /* setting of forms ends here */

  /* token and headers*/
  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }
  const currentActiveStoreId = localStorage.getItem('currentActiveStoreId');
  let store_id = currentActiveStoreId == '' || currentActiveStoreId == undefined ? 1: currentActiveStoreId;
  const [storeId, setStoreId] = useState(store_id);

  const userId = localStorage.getItem('userId');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };
  /* token and headers ends here*/

  useEffect(() => {
    fetchModuleDetails();
    fetchTable();
    setIsLoading(true);
  }, [])

  const fetchModuleDetails = async () => {
    const module_nav_link = window.location.pathname;
    const data = {
      'uuid': userId,
      'module_nav_link': module_nav_link
    }
    try{
      await axios.post('/api/module-access-details', data, config).then(result =>{
        setModuleName(result.data.menu_access[0]['module_name']);
        setModuleDescription(result.data.menu_access[0]['module_description']);
        const icon = result.data.menu_access[0]['module_icon'];
        setModuleIcon(icon.replace('menu-icon tf-icons bx ', ''));
        setCanAdd(result.data.menu_access[0]['can_add']);
        setCanEdit(result.data.menu_access[0]['can_update']);
        setCanDelete(result.data.menu_access[0]['can_delete']);
      }, (err)=>{
        if(err.response.data.message==='Unauthenticated.'){
          window.location.href = '/login';
        } else {
          // alert(err.response.data.message);
          window.toastr.error(err.response.data.message, 'Oops!');
        }
      });
    } catch (e){
      console.log(e);
    }
  }

  const fetchTable = async() => {
    const url = role.toLowerCase() === 'admin' ? '/api/users' : '/api/users/listbystore/' + storeId;
    try{
      await axios.get(url, config ).then(result => {
        SetUsers(result.data.data);
        setAvailableLicenses(result.data.available_licenses);
        setLicensesLen(result.data.available_licenses.length);
        setUsedLicenseLen(result.data.used_licenses);
        setAvailableLicense(result.data.available_licenses.length);
        setLen(result.data.data.length);
        setIsLoading(false);
      })
    }catch(e){
      console.log(e);
      setIsLoading(false);
    }
  }

  const fetchData = async(id) => {
    setIsAdd(false);
    setModal('show');
    setLicenseLabel('License');

    setFirstName('');
    setLastName('');
    setBirthDate('');
    setPhone('');
    setPassword('');
    setId('');
    setEmail('');
    setUuid('');

    try{
      await axios.get('/api/users/' + id, config ).then(result => {
        const data = result.data.data;
        setFirstName(data.name);
        setBirthDate(data.birthdate);
        setEmail(data.email);
        setPhone(data.phone);
        setId(data.id);
        setUuid(data.uuid);
        setSelectedLicense(data.license_id);
        setCurrentLicense(data.license_id);
        setAvailableLicenses(result.data.available_licenses);
        setLicensesLen(result.data.available_licenses.length);
      })
    }catch(e){
      console.log(e);
    }
  }

  const handleAddNew = (event) => {
    fetchTable();
    setIsAdd(true);
    event.preventDefault();
    setFirstName('');
    setLastName('');
    setBirthDate('');
    setPhone('');
    setId('');
    setEmail('');
    setUuid('');

    setModal('show');
    setIsEnabled(true);
    setModalName('Add New Users');
  }

  const handleClose = (event) => {
    event.preventDefault();
    setModal('hide');
    setIsEnabled(false);
    setModalName('');
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsOnProcess(true);

    const data = {
      'name': firstName + ' ' + lastName,
      'email': email,
      'password': password,
      'role': 'merchantuser',
      'birthdate': birthDate,
      'phone': phone,
      'store_id': store_id,
      'license': license,
      'current_license' : currentLicense
    };
    console.log('Submit Data register');
    console.log(data);

      try {
        if (uuid === '') {
          // Save to
          if (Number(license) > 0) {
            const response = await axios.post('/api/register', data);
            // alert('User created successfully!');
            window.toastr.success('User created successfully!', 'Good Job!');
            fetchTable();
            setUuid('');
            setFirstName('');
            setLastName('');
            setPhone('');
            setEmail('');
            setBirthDate('');
            setId('');
            setSelectedLicense(0);
            setModal('hide');
            setIsOnProcess(false);
          } else {
            // alert(`Error(s) occurred:\n${'license, The license is required.'}`);
            window.toastr.error('License is required.', 'Oops!');
            setIsOnProcess(false);
          }
        } else {
          // Update Users
          const data = {
            'name': firstName + ' ' + lastName,
            'email': email,
            'role': 'merchantuser',
            'birthdate': birthDate,
            'phone': phone,
            'store_id': store_id,
            'license': license,
            'current_license' : currentLicense
          };
          const result = await axios.put('/api/users/' + id, data, config);
          setIsOnProcess(false);
          // alert(result.data.message);
          window.toastr.success(result.data.message, 'Good Job!');
          fetchTable();
          setModal('hide');
        }
      } catch (err) {
        console.log(err);

        if (err.response) {
          // Server responded with an error status code (4xx, 5xx)
          console.log('Response data:', err.response.data);
          const errors = err.response.data.error;
          const errorList = Object.entries(errors);

          if (Array.isArray(errorList)) {
            let errorMessages = errorList.map((error, index) => `${index + 1}: ${error}`).join('\n');
            // alert(`Error(s) occurred:\n${errorMessages}`);
            window.toastr.error(errorMessages, 'Oops!');
          } else {
            // alert(`An error occurred: ${errorList}`); // Or handle non-array errorList differently
            window.toastr.error(errorList, 'Oops!');
          }
        } else if (err.request) {
          // Request was made but no response received
          console.log('No response received:', err.request);
          // alert('No response received from server');
          window.toastr.error('No response received from server', 'Oops!');
        } else {
          // Something else went wrong
          console.log('Error:', err.message);
          // alert('An error occurred');
          window.toastr.error('An error occurred', 'Oops!');
        }


        setIsOnProcess(false);
        setModal('hide');
      }
  }

  const data = {
    'store_id' : localStorage.getItem('currentActiveStoreId')
  }

  const handleUpgrade = async(event) => {
    event.preventDefault();
    setIsOnProcess(true);
    try{
      await axios.post('/api/licenses/upgrade', data, config).then(result =>{
        setIsOnProcess(false);
        window.open(result.data.link, '_blank');
      }, (err)=>{
        // alert(err);
        window.toastr.error('Something went wrong. Could not upgrade', 'Oops!');
        setIsOnProcess(false);
      });
    } catch (e){
      console.log(e);
      // alert(e);
      window.toastr.error('Something went wrong. Could not upgrade', 'Oops!');
    }
  }

  function formatDate(date) {
    const d = new Date(date);
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  }

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row mb-12">
          <div id="table-list-container" className="col-md-12">
          <div className="card">
                <h5 className="card-header">
                  {localStorage.getItem('profileName')!=='admin' && <span style={{float:'right'}}>License Used: <span className="badge bg-label-primary me-1">{usedLicenseLen +'/'+ (Number(availableLicense) + Number(usedLicenseLen))}</span></span>}
                  <div className="kpi-card-info">
                    <span>Gyfthint</span>
                      <p>Users </p>
                  </div>
                  <hr/>
                  {canAdd===1 && <button type="submit" className="btn btn-primary btn-sm ms-2" onClick={handleAddNew}> Add &nbsp; <i className="bx bxs-plus-circle gh-h-px-20"></i></button>}
                </h5>
               <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Store</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>License Type</th>
                        <th>Start Date</th>
                        <th>Expiry Date</th>
                        {canEdit===1 && <th>Action</th> }
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {(Array.isArray(users)?users:[]).map(user=>(
                        <tr key={user.id}>
                          <td><span>{user.store_name}</span></td>
                          <td><span>{user.name}</span></td>
                          <td><span>{user.role_id===3?'Admin':'User'}</span></td>
                          <td><span>{user.email}</span></td>
                          <td><span>{user.phone}</span></td>
                          <td><span className="badge bg-label-primary me-1">{user.label}</span></td>
                          <td><span>{formatDate(user.start_date)}</span></td>
                          <td><span>{formatDate(user.expiry_date)}</span></td>
                          <td>{user.role_id!==3?<a onClick={(e)=>fetchData(user.id)}><i className='bx bx-edit'></i></a>:''}</td>
                        </tr>
                      ))}
                      {len===0 && !isLoading &&
                        <tr>
                          <td colSpan={9}>No records found.</td>
                        </tr>
                      }
                      {isLoading &&
                        <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                      }
                      {isLoading &&
                       <tr><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td></tr>
                      }
                      {isLoading &&
                       <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                      }
                    </tbody>
                  </table>
                </div>
                <br/>
              </div>
          </div>
      </div>
      <div className={modal==='show'?'offcanvas offcanvas-end show':'offcanvas offcanvas-end hide'} id="add-new-record">
        <div className="offcanvas-header border-bottom">
          <h5 className="offcanvas-title" id="exampleModalLabel">{modalName}</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={handleClose}></button>
        </div>

        <div className="offcanvas-body flex-grow-1">
        <form className="add-new-record pt-0 row g-2" id="form-add-new-record" onSubmit={handleSubmit}>
          <input type="hidden" id="uuid" name="uuid" defaultValue={uuid} />
          <input type="hidden" id="id" name="id" defaultValue={id} />
          <input type="hidden" id="store_id" name="store_id" defaultValue={currentActiveStoreId} />

          <div className="col-sm-12">
              {isAdd ? <label className="form-label">First Name</label> : <label className="form-label">Name</label>}
              <div className="input-group input-group-merge">
                  <span className="input-group-text"><i className="bx bx-label"></i></span>
                  <input type="text" id="first_name" className="form-control dt-full-name" name="first_name" required defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)} />
              </div>
          </div>

          {isAdd && (
              <div className="col-sm-12">
                  <label className="form-label">Last Name</label>
                  <div className="input-group input-group-merge">
                      <span className="input-group-text"><i className="bx bx-label"></i></span>
                      <input type="text" id="last_name" className="form-control dt-full-name" name="last_name" required defaultValue={lastName} onChange={(e) => setLastName(e.target.value)} />
                  </div>
              </div>
          )}

          <div className="col-sm-12 d-none">
              <label className="form-label">Birthday</label>
              <div className="input-group input-group-merge">
                  <span className="input-group-text"><i className="bx bx-calendar"></i></span>
                  <input type="date" id="birthdate" className="form-control dt-full-name" name="birthdate" defaultValue={birthDate} onChange={(e) => setBirthDate(e.target.value)} />
              </div>
          </div>

          <div className="col-sm-12">
              <label className="form-label">Phone</label>
              <div className="input-group input-group-merge">
                  <span className="input-group-text"><i className="bx bx-phone"></i></span>
                  <input type="text" id="phone" className="form-control dt-full-name" name="phone" defaultValue={phone} onChange={(e) => setPhone(e.target.value)} required />
              </div>
          </div>
          <div className="col-sm-12">
              <label className="form-label">Email</label>
              <div className="input-group input-group-merge">
                  <span className="input-group-text"><i className="bx bxs-envelope"></i></span>
                  <input type="email" id="email" className="form-control dt-full-name" name="email" defaultValue={email} onChange={(e) => setEmail(e.target.value)} required />
              </div>
          </div>

          {isAdd &&
          <div className="col-sm-12">
              <label className="form-label">Password</label>
              <div className="input-group input-group-merge">
                  <span className="input-group-text"><i className="bx bx-lock"></i></span>
                  <input type="password" id="password" className="form-control dt-password" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" name="password" defaultValue={password} onChange={(e) => setPassword(e.target.value)} required />
              </div>
          </div>
          }

          {isOnProcess===false && licensesLen > 0 &&
          <div className="col-sm-12">
              <label className="form-label">{licenseLabel}</label>
              <div className="input-group input-group-merge">
                  <span className="input-group-text"><i className="bx bxs-id-card"></i></span>
                  <select value={license} name="license" id="license" className="form-control" onChange={(e) => setSelectedLicense(e.target.value)}>
                      <option value="">Select License</option>
                      {(Array.isArray(availableLicenses) ? availableLicenses : []).map((license) => (
                          <option key={license.id} value={license.id}>
                              {'L' + license.id.toString().padStart(3, '0')} - {license.label}
                          </option>
                      ))}
                  </select>
              </div>
          </div>
          }
          {isOnProcess===false && licensesLen === 0 &&
          <div className="col-sm-12">
            <div className="card shadow-none bg-transparent border border-warning mb-3">
              <div className="card-body">
                <h5 className="card-title">Oops!</h5>
                <p className="card-text">
                  {/*All available licenses are assigned to existing users.*/}
                  {/*Click <a href="mailto:sales@gyfthint.com"><b>Update Licenses</b></a> to purchase additional add-on licenses for your plan or to upgrade your plan to a new package.*/}
                  {/*To de-activate and re-assign a user license, click <a href=""><b>Manage Users</b></a>*/}
                  You must first purchase additional licenses under your plan before you can add users to get a license. Please click on the <strong>Upgrade or Add Licenses</strong> button at the top right of your screen.
                </p>
                  <a href="#" onClick={handleUpgrade} target="_blank" className="btn btn-sm btn-warning btn-buy-now d-none">Upgrade</a>

              </div>
            </div>
          </div>
          }

          {role.toLowerCase() === 'admin' && (
              <div className="col-sm-12">
                  <label className="form-label">Role</label>
                  <div className="input-group input-group-merge">
                      <span className="input-group-text"><i className="bx bx-globe"></i></span>
                      <select defaultValue={roleid} name="role_id" id="role_id" className="form-control" onChange={(e) => setRoleId(e.target.value)}>
                          <option defaultValue="2">Admin</option>
                          <option defaultValue="3">Merchant</option>
                          <option defaultValue="4">Customer</option>
                      </select>
                  </div>
              </div>
          )}

          <div className="col-sm-12">
              {!isOnProcess ? (
                  <button type="submit" className="btn btn-primary data-submit me-sm-3 me-1">Submit</button>
              ) : (
                  <button type="button" className="btn btn-primary data-submit me-sm-3 me-1" disabled>Saving...</button>
              )}
              <button type="reset" className="btn btn-outline-secondary" data-bs-dismiss="offcanvas">Cancel</button>
          </div>
      </form>


        </div>
      </div>
    </div>
  )
}

export default Users
