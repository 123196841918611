import React, { useEffect, useState } from "react";
import axios from "../api/axios";

const Merchants = () => {
  const [role, setRole] = useState(localStorage.getItem('profileRole'));
  const [modal, setModal] = useState('hide');
  const [defaultStatus, setDefaultStatus] = useState(false);
  const [modalName, setModalName] = useState('');
  const [canAdd, setCanAdd] = useState(0);
  const [canEdit, setCanEdit] = useState(0);
  const [canDelete, setCanDelete] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [len, setLen] = useState(0);
  const [moduleName, setModuleName] = useState('');
  const [moduleDescription, setModuleDescription] = useState('');
  const [moduleIcon, setModuleIcon] = useState('');
  const [licenses, setLicenses] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [editedLicense, setEditedLicense] = useState('');
  const [editedIsPaid, setEditedIsPaid] = useState(0);

  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }

  const userId = localStorage.getItem('userId');
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  useEffect(() => {
    fetchModuleDetails();
    fetchTable();
    fetchLicenses();
    setIsLoading(true);
  }, []);

  const fetchLicenses = async() => {
    try {
      const result = await axios.get('/api/licenses', config);
      console.log('Licenses Data:', result.data.data);
      setLicenses(result.data.data);
    } catch (e) {
      console.error('Error fetching licenses:', e);
    }
  }

  const fetchModuleDetails = async () => {
    const module_nav_link = window.location.pathname;
    const data = {
      'uuid': userId,
      'module_nav_link': module_nav_link
    }
    try{
      const result = await axios.post('/api/module-access-details', data, config);
      setModuleName(result.data.menu_access[0]['module_name']);
      setModuleDescription(result.data.menu_access[0]['module_description']);
      const icon = result.data.menu_access[0]['module_icon'];
      setModuleIcon(icon.replace('menu-icon tf-icons bx ', ''));
      setCanAdd(result.data.menu_access[0]['can_add']);
      setCanEdit(result.data.menu_access[0]['can_update']);
      setCanDelete(result.data.menu_access[0]['can_delete']);
    } catch (e){
      console.log(e);
    }
  }

  const fetchTable = async() => {
    try{
      const result = await axios.get('/api/stores', config);
      console.log(result.data.data);
      setMerchants(result.data.data);
      setLen(result.data.data.length);
      setIsLoading(false);
    }catch(e){
      console.log(e);
      setIsLoading(false);
    }
  }

  const handleAddNew = (event) => {
    event.preventDefault();
    setModal('show');
    setDefaultStatus(true);
    setModalName('Add New Merchants');
  }

  const handleClose = (event) => {
    event.preventDefault();
    setModal('hide');
    setDefaultStatus(false);
    setModalName('');
  }

  const handleEditClick = (merchant) => {
    setEditingRow(merchant.uuid);
    setEditedLicense(merchant.license);
    setEditedIsPaid(merchant.is_paid);
  };

  const handleSaveClick = (merchant) => {
    // Call your update API here with the new license value
    merchant.license = editedLicense;
    const data = {
      'label': editedLicense,
      'is_paid':editedIsPaid,
      'store_id': merchant.id,
      'user_id':merchant.user_id
    };

    try {
      axios.post('/api/stores/updatelicense', data, config)
        .then(result => {
          alert(result.data.message);
          fetchTable();
        })
        .catch(err => {
          console.error('API request failed:', err);
          if (err.response) {
            // Server responded with a status other than 200 range
            console.error('Response data:', err.response.data);
            console.error('Response status:', err.response.status);
            console.error('Response headers:', err.response.headers);
          } else if (err.request) {
            // Request was made but no response was received
            console.error('Request data:', err.request);
          } else {
            // Something else happened in setting up the request
            console.error('Error message:', err.message);
          }
        });
    } catch (e) {
      console.error('Unexpected error:', e);
    }

    setEditingRow(null);
  };


  const handleLicenseChange = (event) => {
    setEditedLicense(event.target.value);
  };

  const handleIspaidChange = (event) => {
    setEditedIsPaid(event.target.value);
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row mb-12">
        <div id="table-list-container" className="col-md-12">
          <div className="card">
            <h5 className="card-header">
              <span className='bx bx-id-card'></span> Merchants
              {canAdd === 1 &&
                <button type="submit" className="btn btn-primary btn-sm ms-2" onClick={handleAddNew}>
                  Add &nbsp; <i className="bx bxs-plus-circle"></i>
                </button>
              }
            </h5>
            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>Merchant</th>
                    <th>E-Commerce Site</th>
                    <th>Platform</th>
                    <th>License Type</th>
                    <th>License Inventory</th>
                    <th>Payment Status</th>
                    {canEdit === 1 && <th>Action</th>}
                  </tr>
                </thead>
                <tbody className="table-border-bottom-0">
                  {(Array.isArray(merchants) ? merchants : []).map(merchant => (
                    <tr key={merchant.uuid}>
                      <td><span className="fw-medium">{merchant.store_name}</span></td>
                      <td><span>{merchant.myshopify_domain}</span></td>
                      <td><span>{'Shopify'}</span></td>
                      <td>
                        {editingRow === merchant.uuid ? (
                          <select
                            value={editedLicense}
                            onChange={handleLicenseChange}
                            onBlur={() => handleSaveClick(merchant)} // Save on blur
                            className="form-control"
                          >
                            {licenses.map(option => (
                              <option key={option.uuid} value={option.label}>
                                {option.label === '' ? 'None' : option.label}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <span className="badge bg-label-primary me-1">
                            {merchant.license === '' ? '' : merchant.license}
                          </span>
                        )}
                      </td>
                      <td><span>{merchant.no_of_users} / {merchant.no_of_users_paid}</span></td>
                      <td>
                        {editingRow === merchant.uuid ? (
                            <select
                              value={editedIsPaid}
                              onChange={handleIspaidChange}
                              onBlur={() => handleSaveClick(merchant)} // Save on blur
                              className="form-control"
                            >
                             <option key={0} value={0}>Unpaid</option>
                             <option key={1} value={1}>Paid</option>
                            </select>
                          ):(
                            merchant.is_paid===0?'Unpaid':'Paid'
                        )}
                      </td>
                      {canEdit === 1 && (
                        <td>
                          {editingRow === merchant.uuid ? (
                            <button onClick={() => handleSaveClick(merchant)} className="btn btn-primary">Save</button>
                          ) : (
                            <a href="#" onClick={() => handleEditClick(merchant)}>
                              <i className='bx bx-edit'></i>
                            </a>
                          )}
                          &nbsp;&nbsp;
                          <a href={'/merchants/'+merchant.uuid} target="_self"><i className='bx bx-window-open'></i></a>
                        </td>
                      )}
                    </tr>
                  ))}
                      {len===0 && !isLoading &&
                        <tr>
                          <td colSpan={6}>No records found.</td>
                        </tr>
                      }
                      {isLoading &&
                        <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                      }
                      {isLoading &&
                       <tr><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td></tr>
                      }
                      {isLoading &&
                       <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                      }
                    </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Merchants;
